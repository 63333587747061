<template>
  <v-btn
    v-if="create"
    @click.native="onClick"
    v-bind="buttonProperties"
    :to="route"
    :href="href"
    :target="linkTarget"
  >
    <span>{{ label|t }}</span>
    <v-icon v-if="icon">{{icon}}</v-icon>
  </v-btn>
</template>

<script>
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  props: {
    config: {
      type: Object,
    },
  },
  methods: {
    onClick() {
      if(typeof this.config.callback != 'function') return
      this.config.callback(window.eagleLodash.cloneDeep(this.formInfo))
    },
  },
  computed: {
    label() {
      if(typeof this.config.label === 'function') {
        return this.config.label(this.formInfo)
      }

      return this.config.label || null
    },
    linkTarget() {
      if(this.config.linkTarget === undefined) return '_blank'
      return this.config.linkTarget || '_blank'
    },
    buttonProperties() {
      if(typeof this.config.buttonProperties === 'function') {
        return this.config.buttonProperties(this.formInfo)
      }

      return null
    },
    route() {
      if(typeof this.config.route === 'function') {
        return this.config.route(this.formInfo)
      }

      return null
    },
    href() {
      if(typeof this.config.href === 'function') {
        return this.config.href(this.formInfo)
      }

      return null
    },
    icon() {
      if(typeof this.config.icon === 'function') {
        return this.config.icon(this.formInfo)
      }

      if(this.config.icon) return this.config.icon

      return null
    },
    create() {
      if(this.loadFailure) return false
      if(typeof this.config.create != 'function') return true
      return this.config.create(this.formInfo)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
